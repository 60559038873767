body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  animation-name: backgroundColorPalette;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  color: #000;
  font-weight: bold;
  text-align: center;
}

@keyframes backgroundColorPalette {
  0% {
    background: #ee6055;
  }
  25% {
    background: #60d394;
  }
  50% {
    background: #aaf683;
  }
  75% {
    background: #ffd97d;
  }
  100% {
    background: #ff9b85;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mint-container {
    border-style: solid;
    border-width: 0px;
    border-color: steelblue;
    border-radius: 30px 30px 30px 30px;
    width: 300px;
    text-align: center;
    vertical-align: middle !important;
    float: auto;
    margin: auto;
    padding-top: 3%;
    padding-bottom: 5%;
    font-size: 1.3em;
    text-align: center;
}

img {
  padding-top: 5%;
}

svg {
  padding-bottom: 10px;
}

.MuiButton-contained {
  position: relative !important;
  border: 5px solid #000 !important;
  color: rgba(255, 255, 255, 1) !important;
  text-decoration: none !important;
  background-color: rgba(228, 34, 104, 1) !important;
  font-family: 'arial' !important;
  font-weight: 700 !important;
  font-size: 1em !important;
  display: block !important;
  padding: 8px !important;
  -webkit-border-radius: 50px !important;
  -moz-border-radius: 50px !important;
  border-radius: 50px !important;
  -webkit-box-shadow: 0px 20px 0px rgba(0, 0, 0, 1), 0px 9px 25px rgba(0, 0, 0, 0) !important;
  -moz-box-shadow: 0px 20px 0px rgba(0, 0, 0, 1), 0px 9px 25px rgba(0, 0, 0, 0) !important;
    box-shadow: 0px 8px 0px rgba(0, 0, 0, 1), 0px 9px 25px rgba(0, 0, 0, 0) !important;
    margin: 50px auto !important;
  width: auto !important;
  text-align: center!important;
  -webkit-transition: all .1s ease !important;
  -moz-transition: all .1s ease !important;
  -ms-transition: all .1s ease !important;
  -o-transition: all .1s ease !important;
  transition: all .1s ease !important;
  }
  
  .MuiButton-contained:hover {
    position: relative !important;
  border: 5px solid #000 !important;
  color: #fffc83 !important;
  text-decoration: none !important;
  background-color: rgba(228, 34, 104, 1) !important;
  font-family: 'arial' !important;
  font-weight: 800 !important;
  font-size: 1em !important;
  display: block !important;
  padding: 8px !important;
  -webkit-border-radius: 50px !important;
  -moz-border-radius: 50px !important;
  border-radius: 50px !important;
  -webkit-box-shadow: 0px 20px 0px rgba(0, 0, 0, 1), 0px 9px 25px rgba(0, 0, 0, 0) !important;
  -moz-box-shadow: 0px 20px 0px rgba(0, 0, 0, 1), 0px 9px 25px rgba(0, 0, 0, 0) !important;
      box-shadow: 0px 5px 0px rgba(0, 0, 0, 1), 0px 9px 25px rgba(0, 0, 0, 0) !important;
      margin: 50px auto !important;
      width: auto !important;
      text-align: center!important;
      -webkit-transition: all .1s ease !important;
      -moz-transition: all .1s ease !important;
      -ms-transition: all .1s ease !important;
      -o-transition: all .1s ease !important;
      transition: all .1s ease !important;
    transform: translateY(4px) !important;
    }


  .MuiButton-containedPrimary {
    position: relative !important;
  border: 5px solid #000 !important;
  color: rgba(255, 255, 255, 1) !important;
  text-decoration: none !important;
  background-color: rgba(80, 219, 37, 1) !important;
  font-family: 'arial' !important;
  font-weight: 700 !important;
  font-size: 1em !important;
  display: block !important;
  padding: 15px !important;
  -webkit-border-radius: 50px !important;
  -moz-border-radius: 50px !important;
  border-radius: 50px !important;
  -webkit-box-shadow: 0px 20px 0px rgba(0, 0, 0, 1), 0px 9px 25px rgba(0, 0, 0, 0) !important;
  -moz-box-shadow: 0px 20px 0px rgba(0, 0, 0, 1), 0px 9px 25px rgba(0, 0, 0, 0) !important;
    box-shadow: 0px 8px 0px rgba(0, 0, 0, 1), 0px 9px 25px rgba(0, 0, 0, 0) !important;
    margin: 50px auto !important;
  width: auto !important;
  text-align: center!important;
  -webkit-transition: all .1s ease !important;
  -moz-transition: all .1s ease !important;
  -ms-transition: all .1s ease !important;
  -o-transition: all .1s ease !important;
  transition: all .1s ease !important;
  }
  
  .MuiButton-containedPrimary:hover {
    position: relative !important;
  border: 5px solid #000 !important;
  color: #fffc83 !important;
  text-decoration: none !important;
  background-color: rgba(80, 219, 37, 1) !important;
  font-family: 'arial' !important;
  font-weight: 800 !important;
  font-size: 1em !important;
  display: block !important;
  padding: 15px !important;
  -webkit-border-radius: 50px !important;
  -moz-border-radius: 50px !important;
  border-radius: 50px !important;
  -webkit-box-shadow: 0px 20px 0px rgba(0, 0, 0, 1), 0px 9px 25px rgba(0, 0, 0, 0) !important;
  -moz-box-shadow: 0px 20px 0px rgba(0, 0, 0, 1), 0px 9px 25px rgba(0, 0, 0, 0) !important;
      box-shadow: 0px 5px 0px rgba(0, 0, 0, 1), 0px 9px 25px rgba(0, 0, 0, 0) !important;
      margin: 50px auto !important;
      width: auto !important;
      text-align: center!important;
      -webkit-transition: all .1s ease !important;
      -moz-transition: all .1s ease !important;
      -ms-transition: all .1s ease !important;
      -o-transition: all .1s ease !important;
      transition: all .1s ease !important;
    transform: translateY(4px) !important;
    }

    .MuiButton-label {
      line-height: 1.4;
    }